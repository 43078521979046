import React from 'react';
import CtaImage from '../components/cta-image';
import Button from '../components/button';
const Cta = () => {
  return ( 
    <section className="cta  d-flex align-items-center justify-content-center inset ">
      <div className="container p-0">
        <div className="row d-flex flex-column " style={{margin: "auto"}}>
          <div className="cta__content p-0 d-flex flex-wrap col-12">
            <div className="cta__picture p-0 col-12 col-md-6 "><CtaImage/></div>
            <div className="cta__info  d-flex justify-content-center align-items-center flex-wrap col-12 col-md-6">
              <div className="cta__box d-flex flex-column">
                <h2>Nie czekaj</h2>
            <h3 className="mb-5">Zarezerwuj stolik</h3>
                <p className="text-center">Zapraszamy na burgery przygotowywane od podstaw wg. własnej receptury. Wierzymy, że tylko najwyższej jakości mieso, zapewnia niepowtarzalny smak. </p>
              <a href="tel:+667102228"><Button title="ZADZWOŃ" backtitle="POJDZONE"/></a>
              </div>

            
            </div>
          </div>
        </div>
      </div>
    </section>
   );
}
 
export default Cta;
