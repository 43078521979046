import React from 'react';
const Button = ({title,backtitle,style}) => {
  return ( 
    <div class={style} >
    <div class="button_base b02_slide_in">
  <div>{title}</div>
        <div></div>
  <div>{backtitle}</div>
    </div>
</div>
   );
}
Button.defaultProps={
  title: 'deafult title',
  backtitle: 'back',
  style:"back mt-5"
}
export default Button;